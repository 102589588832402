import thunk from "redux-thunk";
import { uiReducer } from "../reducers/uiReducer";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";

const composeEnhancers =
  (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
  // load: loadReducer,
  // auth: authReducer,
  ui: uiReducer,
  // notes: notesReducer,
});

export const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
