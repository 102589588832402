import { tipos } from "../types/tipos";

const initialState = {
  appName: "Knox",
  marginTop: 64,
  marginLeft: 0,
  // marginLeft: 80,
  marginBottom: 48,
  textColor: "aliceblue",
};

export const uiReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case tipos.setAll:
      return { ...state, ...payload };

    default:
      return state;
  }
};
